@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
}

button {
    user-select: none;
}

html {
    background-color: #eee;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    font-variant: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
    min-height: 100%;
}
